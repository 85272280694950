<template>
  <div class="container">
    <h3 id="edit_step_two_title">
      <span v-html="$t('segmentation_forms_complete_title')" /> -
      <span v-html="$t('period')" />
      {{ $formatChDate(entireSegmentationFormObject.validFrom) }}&nbsp;-&nbsp;{{ $formatChDate(entireSegmentationFormObject.validUntil) }}
    </h3>
    <div>
      <v-layout row justify-center>
        <div class="mb-4">
          <h1 v-html="$t('segmentation_validation_overview')"></h1>
          <v-row density="compact" loading="loadingValidations">
            <template v-if="segmentationFormValidationGroups.okCount > 0">
              <v-col cols="2" class="pt-3">
                <span v-html="$t('segmentation_validation_ok')"></span>
              </v-col>
              <v-col cols="10">
                <v-chip variant="flat" :color="colors.OK" class="chip-bright ma-2">{{ segmentationFormValidationGroups.okCount }}</v-chip>
              </v-col>
            </template>
            <template v-if="segmentationFormValidationGroups.warningCount > 0">
              <v-col cols="2" class="pt-3">
                <span v-html="$t('segmentation_validation_warning')"></span>
              </v-col>
              <v-col cols="10">
                <v-chip variant="flat" :color="colors.WARNING" class="chip-bright ma-2">{{ segmentationFormValidationGroups.warningCount }}</v-chip>
              </v-col>
            </template>
            <template v-if="segmentationFormValidationGroups.errorCount > 0">
              <v-col cols="2" class="pt-3">
                <span v-html="$t('segmentation_validation_error')"></span>
              </v-col>
              <v-col class="10">
                <v-chip variant="flat" :color="colors.ERROR" class="chip-bright ma-2">{{ segmentationFormValidationGroups.errorCount }}</v-chip>
              </v-col>
            </template>
          </v-row>
        </div>
      </v-layout>
    </div>
    <v-layout row justify-center>
      <v-overlay v-model="loading" persistent fullscreen id="loading-dialog" content-class="loading-dialog">
        <v-container fill-height>
          <v-layout row justify-center align-center>
            <v-progress-circular indeterminate color="secondary"></v-progress-circular>
          </v-layout>
        </v-container>
      </v-overlay>
    </v-layout>
    <v-row v-if="segmentationFormValidationGroups.warningCount > 0 || segmentationFormValidationGroups.errorCount > 0">
      <v-col>
        <v-alert border="top" colored-border type="info" elevation="2">
          <span v-html="$t('segmentation_form_edit_step_two_info')" />
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="6" md="9"
        ><v-checkbox
          v-if="
            $privileges.has({
              path: '/segmentationForms/completion/admin',
              permission: 'read'
            }) ||
            $privileges.has({
              path: '/segmentationForms/completion/reporting',
              permission: 'read'
            })
          "
          id="acceptWarnings"
          v-model="entireSegmentationFormObject.acceptWarnings"
          :label="$t('segmentation_form_edit_step_tree_ignore_warnings_label')"
          name="acceptWarnings"
          :true-value="true"
          :false-value="false"
        />
      </v-col>
      <v-col sm="6" md="9">
        <v-checkbox
          v-if="
            $privileges.has({
              path: '/segmentationForms/completion/admin',
              permission: 'read'
            })
          "
          :disabled="
            !$privileges.has({
              path: '/segmentationForms/completion/admin',
              permission: 'write'
            })
          "
          id="acceptErrors"
          v-model="entireSegmentationFormObject.acceptErrors"
          :label="$t('segmentation_form_edit_step_tree_ignore_errors_label')"
          name="acceptErrors"
          :true-value="true"
          :false-value="false"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-textarea
          v-if="
            $privileges.has({
              path: '/segmentationForms/completion/admin',
              permission: 'read'
            }) ||
            $privileges.has({
              path: '/segmentationForms/completion/reporting',
              permission: 'read'
            })
          "
          :disabled="
            $privileges.has({
              path: '/segmentationForms/completion/controlling',
              permission: 'write'
            })
          "
          filled
          rows="5"
          id="segmentation_form_remarks"
          :label="$t('segmentation_form_edit_step_tree_remarks_label_verwerter')"
          v-model="entireSegmentationFormObject.remark"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-textarea
          v-if="
            $privileges.has({
              path: '/segmentationForms/completion/admin',
              permission: 'write'
            })
          "
          :disabled="
            !$privileges.has({
              path: '/segmentationForms/completion/admin',
              permission: 'write'
            })
          "
          filled
          rows="5"
          id="segmentation_form_remarks_admin"
          :label="$t('segmentation_form_edit_step_tree_remarks_label_admin')"
          v-model="entireSegmentationFormObject.remarkAdmin"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <div>
        <div v-html="$t('segmentation_forms_complete_info')" />
        <v-btn color="secondary" class="shadow-none" @click="goBack()"><span v-html="$t('back')" /></v-btn>
        <v-btn color="secondary" class="shadow-none" @click="cancel()"><span v-html="$t('cancel')" /></v-btn>
        <v-btn color="primary" v-if="!showConfirmation()" class="shadow-none" @click="complete()"><span v-html="$t('complete')" /></v-btn>
        <v-btn color="primary" v-if="showConfirmation()" class="shadow-none" @click="confirmCompletion()"><span v-html="$t('complete')" /></v-btn>
      </div>
    </v-row>
    <v-dialog :retain-focus="false" v-model="dialog" max-width="75%" id="production-form-validation-dialog">
      <v-card>
        <v-card-title><span v-html="$t('segmentation_forms_completion_confirm_title')" /></v-card-title>
        <v-card-text><span v-html="$t('segmentation_forms_completion_confirm_text')" /></v-card-text>
        <v-card-actions>
          <v-btn id="check_btn" class="m-2" color="primary" type="submit" @click="complete()">
            <span v-html="$t('segmentation_forms_completion_confirm_definitively_complete')" />
          </v-btn>
          <v-btn id="check_btn" class="m-2" color="secondary" type="submit" @click="closeDialog()">
            <span v-html="$t('segmentation_forms_completion_confirm_cancel')" />
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { showError } from '@/services/axios'
import { apiURL, emitter } from '@/main'
import { privileges } from '@/services/privileges'
import { useSegmentationStatusStore } from '@/store/enums/SegmentationStatusStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'segmentationForms_edit_step_two',
  data() {
    return {
      segmentationFormValidationGroups: {},
      entireSegmentationFormObject: {
        acceptWarnings: false,
        acceptErrors: false,
        remark: '',
        remarkAdmin: '',
        status: '',
        validFrom: ''
      },
      loading: false,
      loadingValidations: false,
      colors: {
        OK: 'green',
        WARNING: 'yellow darken-3',
        ERROR: 'red'
      },
      icons: {
        OK: 'mdi-checkbox-marked-circle',
        WARNING: 'mdi-alert',
        ERROR: 'mdi-cancel'
      },
      dialog: false
    }
  },
  computed: {
    segmentationStatuses() {
      return useSegmentationStatusStore().segmentationStatuses
    },
    completionAdmin() {
      return privileges.has({
        path: '/segmentationForms/completion/admin',
        permission: 'read'
      })
    },
    activeStatus() {
      let status = this.segmentationStatuses.filter((stat) => stat.name === this.entireSegmentationFormObject.status)
      if (status) {
        return status[0]
      }
      return null
    },
    canCloseAdmin() {
      return this.activeStatus.canCloseAdmin ? true : false
    },
    canClose() {
      return this.activeStatus.canClose ? true : false
    }
  },
  methods: {
    showConfirmation() {
      const [segmentationFormYear] = this.entireSegmentationFormObject.validFrom.split('-')
      const currentYear = new Date().getFullYear()
      const currentMonth = new Date().getUTCMonth()
      // status has to be VALIDATE_TSM, it has to be referrieng to a segmentationForm in the past years, it has to popup in March at the earliest (0=January,1=February)
      if (
        this.activeStatus?.name == 'VALIDATE_TSM' &&
        privileges.has({
          path: '/segmentationForms/completion/admin',
          permission: 'read'
        }) &&
        parseInt(segmentationFormYear) < currentYear &&
        currentMonth > 1
      ) {
        return true
      } else return false
    },
    confirmCompletion() {
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
    },
    async loadSegmentationFormHeader() {
      try {
        this.loading = true
        const result = await this.axios.get(apiURL + '/segmentationForms/' + this.$route.params.id, { headers: { 'Content-Type': 'application/json' } })
        if (await result) {
          this.entireSegmentationFormObject = result.data
          return result.data
        }
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    async getSegmentationFormValidation() {
      try {
        this.loadingValidations = true
        const result = await this.axios.get(
          apiURL + '/segmentationForms/' + this.$route.params.id + '/validation',
          {
            params: {
              segmentationFormId: this.$route.params.id
            }
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          this.loadingValidations = false
          return result.data
        }
      } catch (e) {
        showError(e)
      } finally {
        this.loadingValidations = false
      }
    },
    goBack() {
      this.$router
        .push({
          name: 'segmentationForms_edit_step_one',
          params: { id: this.$route.params.id }
        })
        .catch((err) => {
          // duplicated route
        })
    },
    cancel() {
      this.$router
        .push({
          name: 'segmentationForms_read'
        })
        .catch((err) => {
          // duplicated route
        })
    },
    async complete() {
      this.dialog = false
      this.loading = true
      try {
        const result = await this.axios.patch(
          apiURL + '/segmentationForms/' + this.$route.params.id + '/close',
          {
            acceptWarnings: this.entireSegmentationFormObject.acceptWarnings,
            acceptErrors: this.entireSegmentationFormObject.acceptErrors,
            remark: this.entireSegmentationFormObject.remark,
            remarkAdmin: this.entireSegmentationFormObject.remarkAdmin,
            status: 'VALIDATE_DAIRY'
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          emitter.emit('toastSuccess', {
            message: this.$t('masterdata_legal_entity_edit_toast_success')
          })
          this.$router
            .push({
              name: 'segmentationForms_read'
            })
            .catch((err) => {
              // duplicated route
            })
        }
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    }
  },
  beforeMount() {
    useSegmentationStatusStore().fetchSegmentationStatuses()
  },
  async mounted() {
    this.segmentationFormValidationGroups = await this.getSegmentationFormValidation()
    this.loadSegmentationFormHeader()
  }
})
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
.loading-dialog {
  background-color: $dbm-overlay-color !important;
}
</style>
